import React from "react";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import orderBy from "lodash/orderBy";

import useFileManagerUpload, {
  type FileData
} from "../../hooks/useFileManagerUpload";
import useClickOutside from "../../hooks/useClickOutside";
import { getDownloadFileLink } from "../../api/api";

import styles from "./GridView.module.scss";
import FolderIcon from "./FolderIcon";
import FileIcon from "./FileIcon";
import ContextMenu from "./ContextMenu";

export const ActionButton = ({
  file,
  vendorId
}: {
  file: FileData;
  vendorId: string;
}) => {
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const toggleContextMenu = React.useCallback(() => {
    setMenuOpen(v => !v);
  }, []);

  const closeContextMenu = React.useCallback(() => {
    setMenuOpen(false);
  }, []);
  const wrapperRef = React.useRef(null);
  useClickOutside(wrapperRef, closeContextMenu);

  const handleDownload = React.useCallback(async () => {
    const response = await getDownloadFileLink(file.path, vendorId);
    const url = response.data;
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = file.name;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }, []);

  return (
    <div className={styles["context-menu-toggle"]} ref={wrapperRef}>
      <div className={styles.relative}>
        {isMenuOpen && (
          <div className={styles["context-menu"]}>
            <button
              className={`${styles["context-menu-item"]} ${styles.button}`}
              type="button"
              onClick={handleDownload}
              data-testid="file_download"
            >
              Download
            </button>
          </div>
        )}
        <button
          className={`${styles.button} ${styles["context-toggle"]}`}
          type="button"
          onClick={toggleContextMenu}
          data-testid="file_menu"
        >
          <ContextMenu />
        </button>
      </div>
    </div>
  );
};

export function DirectoryEntry({
  file,
  refetch,
  vendorId
}: {
  file: FileData;
  refetch?: () => void;
  vendorId: string;
}) {
  const history = useHistory();
  const { getRootProps, isDragActive } = useFileManagerUpload(
    file.path,
    vendorId,
    refetch
  );
  return (
    <div className={styles["entry-container"]}>
      <div
        className={styles["entry-body"]}
        {...getRootProps({
          multiple: true
        })}
        onDoubleClick={() => history.push(`/${vendorId}/file${file.path}`)}
        onKeyDown={(e: React.KeyboardEvent<HTMLTableRowElement>) =>
          e.key === "Enter" && history.push(`/${vendorId}/file${file.path}`)
        }
        role="button"
        tabIndex={0}
        style={{ backgroundColor: isDragActive ? "#cccccc" : undefined }}
      >
        <div className={styles["entry-icon-container"]}>
          <div className={styles["entry-icon-info"]}>
            <FolderIcon />
            <h2 className={styles["entry-name"]}>{file.name}</h2>
          </div>
        </div>
        <div className={styles["entry-divider"]} />
        <div className={styles["entry-info-container"]}>
          <div className={styles["entry-info-left"]}>
            <span className={styles["entry-info-title"]}>Date Created:</span>
            <span className={styles["entry-info-value"]}>
              {format(file.date, "yyyy.MM.dd")}
            </span>
          </div>
          <div className={styles["entry-info-right"]} />
        </div>
      </div>
    </div>
  );
}

function FileEntry({ file, vendorId }: { file: FileData; vendorId: string }) {
  return (
    <div className={styles["entry-container"]}>
      <div className={styles["entry-body"]}>
        <ActionButton file={file} vendorId={vendorId} />
        <div className={styles["entry-icon-container"]}>
          <div className={styles["entry-icon-info"]}>
            <FileIcon />
            <h2 className={styles["entry-name"]}>{file.name}</h2>
          </div>
        </div>
        <div className={styles["entry-divider"]} />
        <div className={styles["entry-info-container"]}>
          <div className={styles["entry-info-left"]}>
            <span className={styles["entry-info-title"]}>File Size:</span>
            <span className={styles["entry-info-value"]}>{file.size}</span>
          </div>
          <div className={styles["entry-info-right"]}>
            <span className={styles["entry-info-title"]}>Date Created:</span>
            <span className={styles["entry-info-value"]}>
              {format(file.date, "yyyy.MM.dd")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function GridView({
  fileData,
  refetch,
  column = 3,
  vendorId
}: {
  fileData: FileData;
  refetch?: () => void;
  column: number;
  vendorId: string;
}) {
  const sortedRows = React.useMemo(() => {
    return orderBy(fileData.children, ["type", "date"], ["asc", "desc"]);
  }, [fileData]);
  return (
    <div
      className={
        column === 4
          ? styles["gridview-container-4"]
          : styles["gridview-container-3"]
      }
    >
      {sortedRows.map((file: FileData) => (
        <React.Fragment key={file.path}>
          {file.type === "File" ? (
            <FileEntry file={file} vendorId={vendorId} />
          ) : (
            <DirectoryEntry file={file} refetch={refetch} vendorId={vendorId} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default GridView;
