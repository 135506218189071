import React from "react";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import orderBy from "lodash/orderBy";

import useFileManagerUpload, {
  type FileData
} from "../../hooks/useFileManagerUpload";
import useClickOutside from "../../hooks/useClickOutside";
import { getDownloadFileLink } from "../../api/api";

import styles from "./TableView.module.scss";
import ContextMenu from "./ContextMenu";

export const ActionCell = ({
  file,
  vendorId
}: {
  file: FileData;
  vendorId: string;
}) => {
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const toggleContextMenu = React.useCallback(() => {
    setMenuOpen(v => !v);
  }, []);

  const closeContextMenu = React.useCallback(() => {
    setMenuOpen(false);
  }, []);
  const wrapperRef = React.useRef(null);
  useClickOutside(wrapperRef, closeContextMenu);

  const handleDownload = React.useCallback(async () => {
    const response = await getDownloadFileLink(file.path, vendorId);
    const url = response.data;
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = file.name;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }, []);

  return (
    <td className={styles["action-cell"]} ref={wrapperRef}>
      <div className={styles.relative}>
        {isMenuOpen && (
          <div className={styles["context-menu"]}>
            <button
              className={`${styles["context-menu-item"]} ${styles.button}`}
              type="button"
              onClick={handleDownload}
              data-testid="file_download"
            >
              Download
            </button>
          </div>
        )}
        <button
          className={`${styles.button} ${styles["context-toggle"]}`}
          type="button"
          onClick={toggleContextMenu}
          data-testid="file_menu"
        >
          <ContextMenu />
        </button>
      </div>
    </td>
  );
};

export function TableDirectoryRow({
  file,
  refetch,
  vendorId
}: {
  file: FileData;
  refetch?: () => void;
  vendorId: string;
}) {
  const history = useHistory();
  const { getRootProps, isDragActive } = useFileManagerUpload(
    file.path,
    vendorId,
    refetch
  );
  return (
    <tr
      className={`${styles["table-row"]} ${styles["directory-row"]}`}
      {...getRootProps({
        multiple: true
      })}
      onDoubleClick={() => history.push(`/${vendorId}/file${file.path}`)}
      onKeyDown={(e: React.KeyboardEvent<HTMLTableRowElement>) =>
        e.key === "Enter" && history.push(`/${vendorId}/file${file.path}`)
      }
      role="button"
      tabIndex={0}
      style={{ backgroundColor: isDragActive ? "#cccccc" : undefined }}
      data-testid="file_directoryRow"
    >
      <td>{file.name}</td>
      <td />
      <td />
      <td />
      <td />
    </tr>
  );
}

function TableFileRow({
  file,
  vendorId
}: {
  file: FileData;
  vendorId: string;
}) {
  return (
    <tr className={styles["table-row"]} data-testid="file_tableRow">
      <td>{file.name}</td>
      <td>{file.size}</td>
      <td>{format(file.date, "yyyy.MM.dd")}</td>
      <td>{format(file.date, "yyyy.MM.dd")}</td>
      <ActionCell file={file} vendorId={vendorId} />
    </tr>
  );
}

function TableView({
  fileData,
  refetch,
  vendorId
}: {
  fileData: FileData;
  refetch?: () => void;
  vendorId: string;
}) {
  const sortedRows = React.useMemo(() => {
    return orderBy(fileData.children, ["type", "date"], ["asc", "desc"]);
  }, [fileData]);
  return (
    <table className={styles.table} data-testid="file_tableContainer">
      <thead>
        <tr className={styles["table-row"]}>
          <th>
            <strong>FILE NAME</strong>
          </th>
          <th>
            <strong>FILE SIZE</strong>
          </th>
          <th>
            <strong>DATE UPLOADED</strong>
          </th>
          <th>
            <strong>DATE CREATED</strong>
          </th>
          <th aria-label="action" />
        </tr>
      </thead>
      <tbody className={styles["table-body"]} data-testid="file_tableBody">
        {sortedRows.map((file: FileData) => (
          <React.Fragment key={file.path}>
            {file.type === "File" ? (
              <TableFileRow file={file} vendorId={vendorId} />
            ) : (
              <TableDirectoryRow
                file={file}
                refetch={refetch}
                vendorId={vendorId}
              />
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
}

export default TableView;
