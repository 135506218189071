import React, { useEffect } from "react";
import { useHistory, useLocation, generatePath } from "react-router-dom";
import { FormSelect } from "@nef/core";
import { useQuery } from "jsonapi-react";

import arrow from "../../assets/left-arrow.svg";
import useDefaultAuth from "../../hooks/useDefaultAuth";
import { Vendor } from "../../api/types";
import useQueryParam from "../../hooks/useQueryParam";
import { useSelectedVendor } from "../../contexts/selectedVendor";
import { PATHS } from "../../routes";

import { SidebarNavLink, SidebarNavLinkWithVendor } from "./SidebarNavLink";
import styles from "./Sidebar.module.scss";

const extractIdFromPath = (path: string): string | null => {
  const match = path.match(/\/publishers\/(\d+)$/);
  return match ? match[1] : null;
};

const Sidebar = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = useQueryParam();

  const { selectedVendor, setVendor } = useSelectedVendor();

  const vendorId = queryParams?.get("vendorId") ?? "";

  const {
    authenticatedUser: user,
    logout,
    isManagerAdmin,
    isPublisherUser,
    isOrganizationUser,
    isAdminableVendor,
    isManageableVendor
  } = useDefaultAuth();

  const isOnlyOrganizationUser =
    user?.rolesArray?.length === 1 &&
    user?.rolesArray[0] === "manager_organization_user";

  const { data: vendors } = useQuery<Pick<Vendor, "id" | "name">[]>(
    !isOnlyOrganizationUser && [
      "vendors",
      { sort: "name", "fields[vendors]": "name,id" }
    ]
  );
  const sortedVendors = vendors?.sort((a, b) => a.name.localeCompare(b.name));

  const VENDOR_DROPDOWN =
    sortedVendors?.map(vendor => ({
      value: vendor.id,
      label: vendor.name
    })) ?? [];

  const handleVendorChange = ({ value }: { value: any }) => {
    setVendor(value);
    history.push(
      generatePath(PATHS.EDIT_PUBLISHER, {
        publisherId: value.value
      })
    );
  };

  useEffect(() => {
    if (vendors) {
      if (vendorId) {
        const selectedValue = VENDOR_DROPDOWN?.filter(
          vendor => vendor?.value === vendorId
        );

        setVendor(selectedValue[0]);
      } else if (!selectedVendor) {
        const selectedValue = VENDOR_DROPDOWN[0];
        setVendor(selectedValue);
      }
    }
  }, [vendors, vendorId]);

  useEffect(() => {
    if (vendors && extractIdFromPath(location.pathname)) {
      const selectedId = extractIdFromPath(location.pathname);
      const selectedValue = VENDOR_DROPDOWN?.filter(
        vendor => vendor?.value === selectedId
      );

      setVendor(selectedValue[0]);
    }
  }, [vendors, location.pathname]);

  const isAdmin = user?.rolesArray?.includes("admin") || isManagerAdmin();

  const isManagerPublisherUserAdminWithVendorAccess =
    isPublisherUser() &&
    isAdminableVendor(selectedVendor?.value ? selectedVendor?.value : "0");

  const isManagerPublisherUserWithVendorAccess =
    isPublisherUser() &&
    isManageableVendor(selectedVendor?.value ? selectedVendor?.value : "0");

  return (
    <section className={styles.sidebar}>
      {isAdmin && (
        <div className={styles["sidebar-container"]}>
          <ul className={styles.list}>
            <li>
              <SidebarNavLink to={PATHS.PUBLISHERS} pattern={PATHS.PUBLISHERS}>
                Publishers
              </SidebarNavLink>
            </li>
            <li>
              <SidebarNavLink to={PATHS.PRODUCTS} pattern={PATHS.PRODUCTS}>
                Products
              </SidebarNavLink>
            </li>
            <li>
              <SidebarNavLink to={PATHS.DATATABLES} pattern={PATHS.DATATABLES}>
                Data Tables
              </SidebarNavLink>
            </li>
            <li>
              <SidebarNavLink
                to={PATHS.ORGANIZATIONS}
                pattern={PATHS.ORGANIZATIONS}
              >
                Organizations
              </SidebarNavLink>
            </li>
            <li>
              <SidebarNavLink to={PATHS.AI_SKILLS} pattern={PATHS.AI_SKILLS}>
                AI Skills
              </SidebarNavLink>
            </li>
          </ul>
        </div>
      )}

      {isOrganizationUser() && (
        <div className={styles["sidebar-container"]}>
          <ul className={styles.list}>
            <li>
              <SidebarNavLink
                to={PATHS.ORGANIZATIONS}
                pattern={PATHS.ORGANIZATIONS}
              >
                Organizations
              </SidebarNavLink>
            </li>
          </ul>
        </div>
      )}

      <div className={styles["sidebar-container"]}>
        {!!vendors && (
          <div className={styles["dropdown-container"]}>
            <p className={styles["dropdown-label"]}>Select Publishers</p>
            <FormSelect
              options={VENDOR_DROPDOWN}
              isClearable={false}
              value={selectedVendor}
              onChange={handleVendorChange}
              classNamePrefix="modalFormSelect"
            />
          </div>
        )}
        {selectedVendor && (
          <ul className={`${styles.list} ${styles.margin}`}>
            {(isAdmin || isManagerPublisherUserWithVendorAccess) && (
              <>
                <li>
                  <SidebarNavLinkWithVendor
                    to={generatePath(PATHS.EDIT_PUBLISHER, {
                      publisherId: selectedVendor.value
                    })}
                    pattern={generatePath(PATHS.EDIT_PUBLISHER, {
                      publisherId: selectedVendor.value
                    })}
                  >
                    Dashboard
                  </SidebarNavLinkWithVendor>
                </li>
                <li>
                  <SidebarNavLinkWithVendor
                    to={`${PATHS.PRODUCTS}?vendorId=${selectedVendor.value}`}
                    pattern={`${PATHS.PRODUCTS}?vendorId`}
                  >
                    Products
                  </SidebarNavLinkWithVendor>
                </li>
              </>
            )}

            {isAdmin && (
              <li>
                <SidebarNavLinkWithVendor
                  to={`${PATHS.DATATABLES}?vendorId=${selectedVendor.value}`}
                  pattern={`${PATHS.DATATABLES}?vendorId`}
                >
                  Data Tables
                </SidebarNavLinkWithVendor>
              </li>
            )}

            {(isAdmin || isManagerPublisherUserAdminWithVendorAccess) && (
              <>
                <li>
                  <SidebarNavLinkWithVendor
                    to={`${PATHS.USERS}?vendorId=${selectedVendor?.value}`}
                    pattern={PATHS.USERS}
                  >
                    Users
                  </SidebarNavLinkWithVendor>
                </li>
                <li>
                  <SidebarNavLinkWithVendor
                    to={`${PATHS.USERGROUPS}?vendorId=${selectedVendor?.value}`}
                    pattern={PATHS.USERGROUPS}
                  >
                    User Groups
                  </SidebarNavLinkWithVendor>
                </li>
              </>
            )}
          </ul>
        )}
      </div>

      <h4 className={styles["sidebar-heading"]}>Profile</h4>
      <ul className={styles["profile-list"]}>
        <li>
          <a
            href={`${process.env.REACT_APP_DATA_LINK_URL}/account/profile`}
            className={styles["list-item"]}
          >
            Settings
          </a>
        </li>
        <li>
          <a
            href={process.env.REACT_APP_DATA_LINK_URL}
            className={styles["list-item"]}
          >
            Data Link
          </a>
        </li>
      </ul>

      <div className={styles["logout-container"]}>
        <ul className={styles["profile-list"]}>
          <li>
            <a
              href={process.env.REACT_APP_FAQ_LINK || ""}
              className={styles["list-item"]}
            >
              Help
            </a>
          </li>
          <li>
            <button
              type="button"
              className={styles["list-item"]}
              onClick={logout}
            >
              <img
                src={arrow}
                className={styles["back-icon"]}
                alt="CSV file icon"
              />
              Log out
            </button>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Sidebar;
